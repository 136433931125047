<template>
  <div>
    <h6 class="text-success">Histórico</h6>
    <h6 class="text-info">Descrição completa do agendamento</h6>

    <b-card>
      <h5 class="p-2 text-center text-primary" v-if="notFound != ''">
        {{ notFound }} <b-icon-journal-x></b-icon-journal-x>
      </h5>
      <div class="tableResponsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Nome Cliente</th>
              <th scope="col">CNPJ/CPF</th>
              <th scope="col">Tipo de Suporte</th>
              <th scope="col">Contato</th>
              <th scope="col">Iniciar/Finalizar</th>
              <th scope="col">Visualizar</th>
              <th scope="col">Historico</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sup in suporte" :key="sup.uuid">
              <td>{{ sup.nome }}</td>
              <td>{{ sup.cpfcnpj }}</td>
              <td>{{ sup.tipoSuporte }}</td>
              <td>{{ sup.contato }}</td>
              <td>
                <b-button
                  v-if="sup.dataInicioAtendimento === null"
                  variant="primary"
                  size="sm"
                  pill
                  @click="iniciar(sup.uuid)"
                  ><b-icon-play-fill></b-icon-play-fill> Iniciar</b-button
                >
                <b-button
                  v-else
                  variant="primary"
                  size="sm"
                  pill
                  @click="finalizar(sup.uuid)"
                  ><b-icon-check-circle-fill></b-icon-check-circle-fill>
                  Finalizar</b-button
                >
              </td>
              <td>
                <b-button
                  variant="info"
                  size="sm"
                  pill
                  @click="visualizar(sup.uuid)"
                  ><b-icon-eye-fill></b-icon-eye-fill> Visualizar</b-button
                >
              </td>
              <td>
                <b-button
                  variant="success"
                  size="sm"
                  pill
                  v-b-modal.modal-lg
                  @click="historico(sup.cpfcnpj), historicoLegado(sup.cpfcnpj)"
                  ><b-icon-card-list></b-icon-card-list> Histórico</b-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <ModalHistorico
      @historicoUuid="historicoEnvent($event)"
      :history="historicoData"
      :historyLegado="historicoDataLegado"
    />
  </div>
</template>

<script>
import ModalHistorico from "../../components/Modals/modal-historico.vue";
import { http } from "../../services/config";
export default {
  props: {
    novoAgendamento: {
      typeo: Boolean,
    },
  },
  components: {
    ModalHistorico,
  },
  created() {
    this.listSuporte();
  },
  watch: {
    novoAgendamento() {
      this.listSuporte();
    },
  },
  data() {
    return {
      notFound: "",
      historicoData: [],
      historicoDataLegado: [],
      nomeClienteAtendido: "",
      observacaoAtendimento: "",
      justificativaDemoraAtendimento: "",
      suporte: [],
      keys: [
        { key: "nome" },
        { key: "cpfcnpj" },
        { key: "tipoSuporte" },
        { key: "contato" },
      ],
      fields: [
        { titulo: "Nome Cliente" },
        { titulo: "CNPJ/CPF" },
        { titulo: "Tipo de Suporte" },
        { titulo: "Contato" },
        { titulo: "Iniciar/Finalizar" },
        { titulo: "Histórico" },
        { titulo: "Visualizar" },
      ],
    };
  },
  methods: {
    async iniciar(uuid) {
      try {
        const url = `/schedules/initialize/${uuid}`;
        await http.put(url, {});
        this.$emit("refresh");
        await this.listSuporte();
      } catch (error) {
        this.$toast.open({
          message: "Erro ao iniciar",
          type: "info",
          // all of other options may go here
        });
      }
    },
    visualizar(uuid) {
      this.$emit("visualizaUuid", uuid);
    },

    historicoEnvent(uuid) {
      this.$emit("historicoEvent", uuid);
      this.$bvModal.hide("modal-lg-history");
    },

    async historicoLegado(cpfcnpj) {
      try {
        const formatedCpfCnpj = cpfcnpj.match(/\d/g).join("");

        const urlLegado = `/gestao/legado/historico/${formatedCpfCnpj}`;
        const { data } = await http.get(urlLegado);
        this.historicoDataLegado = data;
        this.historicoDataLegado.map((el) => {
          el.dataagendamento =
            el.dataagendamento === null
              ? ""
              : new Date(el.dataagendamento).toISOString().split("T")[0];
          el.datafimatendimento =
            el.datafimatendimento === null
              ? ""
              : new Date(el.datafimatendimento).toISOString().split("T")[0];
          el.datainicioatendimento =
            el.datainicioatendimento === null
              ? ""
              : new Date(el.datainicioatendimento).toISOString().split("T")[0];
        });
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Histórico Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
          message: "Erro no historico legado",
          type: "info",
          // all of other options may go here
        });
      }
    },
    async historico(cpfcnpj) {
      try {
        const formatedCpfCnpj = cpfcnpj.match(/\d/g).join("");
        const url = `/schedules/search/historySchedules/${formatedCpfCnpj}`;
        const { data } = await http.get(url);
        this.historicoData = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$toast.open({
            message: "Nenhum Histórico Encontrado",
            type: "info",
            // all of other options may go here
          });
          return;
        }
        this.$swal("Agendamento", "Erro ao visualizar Historico", "error");
      }
    },
    async finalizar(uuid) {
      const url = `schedules/time/schedules/${uuid}`;
      const { data } = await http.get(url);
      if (data.alerta) {
        this.$swal
          .fire({
            title: "Deseja realizar a baixa?",
            text: "Nome do Cliente Atendido",
            input: "text",
            //   inputValue:""
            showCancelButton: true,
            confirmButtonColor: "#3CB371",
            confirmButtonText: "Pronto",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            this.nomeClienteAtendido = result.value;
            if (result.isConfirmed === true) {
              this.$swal
                .fire({
                  title: "Deseja realizar a baixa?",
                  text: "Descrição do Atendimento",
                  input: "textarea",
                  showCancelButton: true,
                  confirmButtonColor: "#3CB371",
                  confirmButtonText: "Realizar Baixa",
                  cancelButtonText: "Cancelar",
                })
                .then(async (result2) => {
                  this.observacaoAtendimento = result2.value;
                  if (result2.isConfirmed === true) {
                    this.$swal
                      .fire({
                        title:
                          "Digite a Justificativa para a demora no atendimento",
                        text: "Justificativa",
                        input: "textarea",
                        showCancelButton: true,
                        confirmButtonColor: "#3CB371",
                        confirmButtonText: "Realizar Baixa",
                        cancelButtonText: "Cancelar",
                      })
                      .then(async (result3) => {
                        if (result3.isConfirmed === true) {
                          this.justificativaDemoraAtendimento = result3.value;
                          if (result3.value === "") {
                            return this.$toast.open({
                              message: "A Justificativa é Obrigatoria",
                              type: "error",
                              // all of other options may go here
                            });
                          }
                          await this.baixaSuporte(uuid);
                          this.$emit("refresh");
                        }
                      });
                  }
                });
            }
          });
      } else {
        this.$swal
          .fire({
            title: "Deseja realizar a baixa?",
            text: "Nome do Cliente Atendido",
            input: "text",
            //   inputValue:""
            showCancelButton: true,
            confirmButtonColor: "#3CB371",
            confirmButtonText: "Pronto",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            this.nomeClienteAtendido = result.value;
            if (result.isConfirmed === true) {
              this.$swal
                .fire({
                  title: "Deseja realizar a baixa?",
                  text: "Descrição do Atendimento",
                  input: "textarea",
                  showCancelButton: true,
                  confirmButtonColor: "#3CB371",
                  confirmButtonText: "Realizar Baixa",
                  cancelButtonText: "Cancelar",
                })
                .then(async (result2) => {
                  if (result2.isConfirmed === true) {
                    this.observacaoAtendimento = result2.value;
                    await this.baixaSuporte(uuid);
                    this.$emit("refresh");
                  }
                });
            }
          });
      }
    },

    async baixaSuporte(uuid) {
      try {
        if (
          this.observacaoAtendimento === "" ||
          this.nomeClienteAtendido === ""
        ) {
          return this.$swal(
            "Agendamento",
            "Informe o nome do Cliente e a Observação",
            "info"
          );
        }
        const url = `/schedules/finish/${uuid}`;
        await http.put(url, {
          observacaoAtendimento: this.observacaoAtendimento,
          nomeClienteAtendido: this.nomeClienteAtendido,
          justificativaDemoraAtendimento: this.justificativaDemoraAtendimento,
          status: "Finalizado",
        });
        this.listSuporte();
        this.$emit("refresh");
        this.$swal("Agendamento", "Baixa Realizada com Sucesso", "success");
      } catch (error) {
        this.$swal("Agendamento", "Erro ao dar Baixa", "error");
      }
    },
    async listSuporte() {
      try {
        this.suporte = [];
        this.notFound = "";
        const url = `/schedules/search/user`;
        const { data } = await http.get(url);
        this.suporte = data;

        this.suporte.forEach((element) => {
          switch (element.tipoSuporte) {
            case "ERROSISTEMA":
              element.tipoSuporte = "Erro Sistema";
              break;
            case "ERRONFE":
              element.tipoSuporte = "Erro NF-e";
              break;
            case "ERROCONFIGURACAO":
              element.tipoSuporte = "Erro Configuração";
              break;
            case "ERRONFCE":
              element.tipoSuporte = "Erro NFC-e";
              break;
            case "ERROMDF":
              element.tipoSuporte = "Erro MDF-e";
              break;
            case "ERRO_SISTEMA_NAO_ABRE":
              element.tipoSuporte = "Erro Sistema não Abre";
              break;
            case "ERRO_SISTEMA_PROGRAMACAO":
              element.tipoSuporte = "Erro Sistema Programação";
              break;
            case "RELATORIO_GO":
              element.tipoSuporte = "Relatório - GO";
              break;
            case "RELATORIO_RS":
              element.tipoSuporte = "Relatório - RS";
              break;
            case "RELATORIO_ES":
              element.tipoSuporte = "Relatório - ES";
              break;
            case "RELATORIO_MT":
              element.tipoSuporte = "Relatório - MT";
              break;
            case "RELATORIO_SC":
              element.tipoSuporte = "Relatório - SC";
              break;
            default:
              break;
          }
          console.log(element.tipoSuporte);
        });
      } catch (error) {
        if (error.response.status === 404) {
          this.notFound = "Nenhum Agendamento Encontrado";
          return;
        }
        this.$swal(
          "Agendammento",
          "Ocorreu um erro ao listar agendamentos",
          "error"
        );
      }
    },
  },
};
</script>

<style>
.tableResponsive {
  overflow: auto;
}
</style>