<template>
  <b-card class="mt-4 chartVue shadow">
    <h5 class="text-center p-2">Gráfico de Atendimento Semanal</h5>
    <h6 class="text-center">Média de Atendimento (Semanal) <b-badge variant="info">{{baixaSemanal.length/5}}</b-badge></h6>
    <LineChart width="700" :chart-data="datacollection"></LineChart>
  </b-card>
</template>

<script>
import LineChart from "./LineChart";
 import moment from 'moment'
export default {
  props: {
    baixaSemanal: {
      type: Array,
    },
  },
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: null,
      datas: [],
      dataChart: [],
    };
  },
  created() {
    this.fillData();
    this.objectChart();
  },
  methods: {
    compareDate(a, b) {
      return parseInt(moment(a.dataFimAtendimento).format("DD/MM/YYYY")) - parseInt(moment(b.dataFimAtendimento).format("DD/MM/YYYY"));
    },

    objectChart() {
      this.datas = [];
       this.baixaSemanal.sort(this.compareDate)
      this.baixaSemanal.map((el) => {
        this.datas.push(
          moment(el.dataFimAtendimento).format("DD/MM/YYYY")
        );
    
      
      });
      const novoArray = [...new Set(this.datas)];
      return [...novoArray];
    },

    datachart() {
      const a = this.datas.reduce(function (object, item) {
        if (!object[item]) {
          object[item] = 1;
        } else {
          object[item]++;
        }
        return object;
      }, {});
      const arrData = [];
      Object.keys(a).forEach(function (item) {
        arrData.push(a[item]);
      });
      return arrData;
    },

    async fillData() {
      const lab = this.objectChart();
      const dat = this.datachart();
      this.datacollection = {
        labels: lab,
        datasets: [
          {
            label: "Total de Baixas do Suporte",
            backgroundColor: "rgb(32,178,170,0.5)",
            borderColor: ["rgb(32,178,170)"],
            data: dat,
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>

<style>
.chartVue {
  border-radius: 5px;

  background-color: white;
}
</style>