<template>
  <div>
    <MenuBar />
    <b-card no-body class="m-2">
      <b-tabs v-model="tabIndex" card>
        <b-tab title="Cadastro" active>
          <b-card>
            <FormUser :userSelect="userSelect"/>
          </b-card>
        </b-tab>
        <b-tab title="Pesquisa">
          <b-card>
            <b-form-group label="Pesquisa">
              <b-input @dblclick="listUser" class="col-sm-5"> </b-input>
            </b-form-group>
            <b-card>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Nome do Usuário</th>
                    <th scope="col">Editar</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.uuid">
                    <td>{{user.username}}</td>
                    <td>
                      <b-button size="sm" variant="primary" @click="editar(user.username)">Editar</b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <Table :fields="fields" :keys="keys" :dataProps="users">
                <template #buttonAcoes="{ uuid }">
                  <b-button @click="editar(uuid)" variant="primary"
                    >Editar</b-button
                  >
                </template>
              </Table> -->
            </b-card>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import FormUser from "../../components/forms/FormUser.vue";
import { http } from "../../services/config";

export default {
  components: {
    MenuBar,
    FormUser,
  },
  data() {
    return {
      tabIndex: 0,
      users: [],
      userSelect: {},
      keys: [{ key: "username" }],
      fields: [{ titulo: "Nome do Usuário" }, { titulo: "Editar" }],
    };
  },

  methods: {
    async editar(nome) {
      try {
        const url = `/users/${nome}`;
      const { data } = await http.get(url);
      this.userSelect = data
      this.tabIndex = 0
      } catch (error) {
        this.$swal("Usuário","erro ao editar Usuário","error")
      }
      

    },
    async listUser() {
      try {
        const url = `/users/list/combobox`;
        const { data } = await http.get(url);
        this.users = data;
      } catch (error) {
        this.$swal("Usuário","erro ao listar Usuário","error")
      }
    },
  },
};
</script>

<style>
</style>