<template>
<div class="containerG">
  <div class="imgOlho">
        <img src="https://www.tactus.com.br/wp-content/uploads/2020/08/financeira-site.png" width="500" alt="">
    </div>
   
    
    <form action="">
    <h2>Faça seu Login</h2>

        <img :src="logo" alt="" srcset="">
        <div class="containerInputs">
           
            <input type="text" v-model="login.username" placeholder="Login" />
            <input type="password" v-model="login.password" placeholder="Senha" />
            <b-button @click="logar" variant="info" block class="mt-4" style="height: 40px">Entrar</b-button>
        </div>
        <small>&copy; BMS LTDA 2021 </small>
    </form>
</div>
  <!-- <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <form onsubmit="event.preventDefault()" class="box">
            <div class="logo">
              <img :src="logo" alt="" srcset="" />
            </div>
            <h3 class="mb-4 mt-4">Login Gestão BMS</h3>
            <input
              type="text"
              name=""
              v-model="login.username"
              placeholder="Usuário"
            />
            <input
              type="password"
              name=""
              v-model="login.password"
              placeholder="Senha"
            />
            <input
              type="submit"
              name=""
              @click="logar"
              value="Entrar"
              href="#"
            />
          </form>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import logo from "../../assets/LogoBms-min.png";
import { http } from "../../services/config";
export default {
  data() {
    return {
      logo: logo,
      login: {
        username: "",
        password: "",
      },
    };
  },

  methods: {
    async logar() {
      event.preventDefault()
      try {
        const url = "/users/auth/login";
        const { data } = await http.post(url, {
          username: this.login.username,
          password: this.login.password,
        });
        
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("username", data.user.username);
        sessionStorage.setItem("setor", data.user.setor);
        this.$router.push({
          path: "/Home",
        });
      } catch (error) {
        if(error.response.status === 401){
           return this.$toast.open({
            message: "Usuário não Autorizado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
            message: "Ocorreu um erro ao logar",
            type: "error",
            // all of other options may go here
          });
      }
    },
  },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Staatliches&family=Varela+Round&display=swap");
.card {
  font-family: "Varela Round", sans-serif;
  margin-bottom: 20px;
}

.logo {
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.76);
  border-radius: 15px;
  box-shadow: 0px 10px 13px -7px #272727, 5px 5px 14px 5px rgba(0, 0, 0, 0);
}
@media (max-width: 700px) {
  .box {
    width: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 35% !important;
  }

  .logo {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    background-color: #fff;
    border-radius: 20px;
    width: 90%;
    margin: 0 auto;
  }

  .box input[type="text"]:focus,
  .box input[type="password"]:focus {
    width: 90% !important;
    border-color: #2ecc71;
  }
}

.box {
  width: 500px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #3f3f3f;
  text-align: center;
  transition: 0.25s;
  margin-top: 100px;
  border-radius: 1px;
  transition: 0.8s ease-out;
}
-webkit-box-shadow: 0px 10px 13px -7px #000000,
  6px 2px 18px 5px rgba(0, 0, 0, 0.58);
box-shadow: 0px 10px 13px -7px #000000, 6px 2px 18px 5px rgba(0, 0, 0, 0.58);

.box:hover {
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    6px 2px 18px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 10px 13px -7px #000000, 6px 2px 18px 5px rgba(0, 0, 0, 0.58);
}

.box input[type="text"],
.box input[type="password"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #797979;
  padding: 10px 10px;
  width: 90%;
  outline: none;
  color: white;
  border-radius: 11px;
  transition: 0.25s;
  
}

.box h3 {
  color: white;
  font-weight: 500;
}

.box input[type="text"]:hover,
.box input[type="password"]:hover {
  -webkit-box-shadow: 0px 10px 13px -7px #302f2f,
    6px 2px 18px 5px rgba(59, 59, 59, 0.58);
  box-shadow: 0px 10px 13px -7px #313131, 6px 2px 18px 5px rgba(56, 56, 56, 0.58);
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  width: 93%;
  border-color: white;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    6px 2px 18px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 10px 13px -7px #000000, 6px 2px 18px 5px rgba(0, 0, 0, 0.58);
}

.box input[type="submit"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #ffffff;
  padding: 14px 40px;
  outline: none;
  color: white;
  border-radius: 14px;
  transition: 0.25s;
  cursor: pointer;

}

.box input[type="submit"]:hover {
  background: #ffffff;
  color: #131313;
}

.forgot {
  text-decoration: underline;
}

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
}

.social-network a.icoFacebook:hover {
  background-color: #3b5998;
}

.social-network a.icoTwitter:hover {
  background-color: #33ccff;
}

.social-network a.icoGoogle:hover {
  background-color: #bd3518;
}

.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
.social-network a.icoGoogle:hover i {
  color: #fff;
}

a.socialIcon:hover,
.socialHoverClass {
  color: #44bcdd;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
}

.social-circle li i {
  margin: 0;
  line-height: 50px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  transform: rotate(360deg);
  transition: all 0.2s;
}

.social-circle i {
  color: #fff;
  transition: all 0.8s;
  transition: all 0.8s;
} */
 @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@200&display=swap');
 
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        body {
            font-family: 'Roboto', sans-serif;
            background: #43c6ac;
            /* fallback for old browsers */
            background: -webkit-linear-gradient(to bottom,
                    #191654,
                    #43c6ac) fixed;
            /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to bottom,
                    #191654,
                    #43c6ac) fixed;
        }

        form {
            background-color: white;
            max-width: 500px;
            width: 70%;
            padding-top: 351px;
            height: 100%;
            padding-left: 50px;
            padding-right: 50px;
            position: absolute;

        }

        form input[type=text]:focus,
        form input[type=password]:focus {
            outline: none;

        }

        form input[type=text]:hover,
        form input[type=password]:hover {
            padding-left: 30px;
            color: turquoise;
        }


        form input[type=submit]:hover {
            background-color: rgb(45, 167, 154);
        }

        form input[type=submit] {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            border: none;
            margin-top: 35px;
            background-color: turquoise;
            color: white;
            font-size: 18px;
            cursor: pointer;
            transition: 0.5s;
            letter-spacing: .1rem;
        }

        form input[type=text],
        form input[type=password] {
            width: 100%;
            height: 45px;
            border: 1px solid #43c6ac;
            padding-left: 10px;
            margin: 10px 0;
            font-size: 15px;
            transition: 0.7s;
            color: #666;
            border-left: none;
            border-right: none;
            border-top: none;
            color: turquoise;
            text-decoration: none;
            letter-spacing: .1rem;
            background-color: white!important;
        }
        small{
            left: 15px;
            position: absolute;
            top: 96%; 
        }

        .imgOlho{
            position: absolute;
            right: 50%;
            top: 20%;
           
        }

        img {
            margin: auto 16%;
            position: absolute;
            top: 6%;
        }

        h1{
            font-family: 'Montserrat Alternates', sans-serif;
            position: absolute;
            top: 67%;
            left: 48%;
            color: rgb(243, 246, 247);
            font-size: 50px;
            letter-spacing: .4rem;
        }
       
        h2{
            font-family: 'Montserrat Alternates', sans-serif;
            font-size: 25px;
            letter-spacing: .2rem;
            text-align: center;
            position: absolute;
            top: 30%;
            left: 24%;
            font-weight: bold;
            }
/* 
        .containerInputs{
            position: absolute;
            background-color: rgba(0, 128, 128, 0.089);
           width: 95%;
           left: 10px;
           padding: 20px;
           border-radius: 10px;
           
        } */

        @media (max-width: 700px) {

            .imgOlho{
                display: none;
            }

           

            h1{
                display: none;
            }
            form {
                width: 95%;
                height: 900px;
                margin-left: 2.5%;
                padding-left: 15px;
                padding-right: 15px;
            }

            form input[type=text],
            form input[type=password] {
                width: 100%;
            }
        }

        @media (max-width: 1033px) {
            .imgOlho{
                display: none;
            }

            h1{
                display: none;
            }
        }
</style>