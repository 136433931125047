<template>
  <b-modal
    id="modalCreateResponsible"
    size="xl"
    title="Responsável técnico"
    ok-only
    ok-title="Fechar"
    ok-variant="secondary"
    button-size="sm"
  >
    <b-tabs pills card>
      <b-tab title="Cadastrar" active>
        <slot name="createResponsible">
          <FormResponsible :idEmpresa="idEmpresa" :resp="responsible" />
        </slot>
      </b-tab>
      <b-tab title="Pesquisar">
        <slot name="searchResponsible">
          <SearchResponsible
            :idEmpresa="idEmpresa"
            @responsavel="responsible = $event"
          />
        </slot>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import SearchResponsible from "../Search/search-responsible.vue";
import FormResponsible from "../forms/FormResponsible.vue";

export default {
  components: {
    SearchResponsible,
    FormResponsible,
  },
  props: {
    idEmpresa: {
      type: String,
    },
  },
  data() {
    return {
      responsible: {},
    };
  },
};
</script>

<style>
</style>