<template>
  <div>
    <MenuBar />
    <b-card no-body class="m-2">
      <b-tabs v-model="tabIndex" card>
        <b-tab title="Solicitações" active>
          <b-form-group label="Pesquisar responsável">
            <div class="d-flex">
              <b-select
                :options="optionsFilter"
                v-model="selected"
                class="col-5"
              ></b-select>
              <b-input
                v-if="selected?.type === 'text'"
                v-model="nameOrCpf"
                class="col-sm-5 ml-4"
              >
              </b-input>
              <b-input
                type="date"
                v-if="selected?.type === 'date'"
                v-model="nameOrCpf"
                class="col-sm-2 ml-4"
              >
              </b-input>

              <div
                class="ml-3 d-flex"
                style="position: relative"
                v-if="selected?.type === 'selectbox'"
              >
                <b-form-select
                  :options="clientes"
                  v-model="nameOrCpf"
                  size="md"
                  value-field="uuid"
                  text-field="nome"
                ></b-form-select>

                <span
                  class="mr-3"
                  style="
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    right: 15px;
                    top: 10%;
                    background-color: #007bff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    cursor: pointer;
                  "
                >
                  <b-icon-search
                    variant="light"
                    @click="openModals('modalForResp')"
                  ></b-icon-search
                ></span>
              </div>

              <div>
                <b-button
                  v-model="nameOrCpf"
                  variant="primary"
                  @click="resposableByParams"
                  class="ml-4"
                >
                  Pesquisar
                </b-button>
              </div>
            </div>

            <div class="d-flex mt-4" style="gap: 10px">
              <h4><b-icon-filter></b-icon-filter></h4>
              <b-form-group>
                <b-button
                  :variant="
                    statusFilter === 'ACTIVE' ? 'success' : 'outline-success'
                  "
                  @click="getResposaveisByStatus('ACTIVE')"
                  >Aprovado</b-button
                >
              </b-form-group>
              <b-form-group>
                <b-button
                  :variant="
                    statusFilter === 'DENIED' ? 'danger' : 'outline-danger'
                  "
                  @click="getResposaveisByStatus('DENIED')"
                >
                  Reprovado</b-button
                >
              </b-form-group>
              <b-form-group>
                <b-button
                  :variant="
                    statusFilter === 'PENDING' ? 'primary' : 'outline-primary'
                  "
                  @click="getResposaveisByStatus('PENDING')"
                  >Pendente</b-button
                >
              </b-form-group>
            </div>
          </b-form-group>
          <b-card>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Empresa</th>
                  <th scope="col">CNPJ</th>
                  <th scope="col">Nome responsável</th>
                  <th scope="col">CPF</th>
                  <th scope="col">CREA/CFTA</th>
                  <th scope="col">Visualizar</th>
                  <th scope="col">Aprovar</th>
                  <th scope="col">Reprovar</th>
                  <th scope="col">Excluir</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="responsavel in responsaveis" :key="responsavel.uuid">
                  <td>
                    <b-badge :variant="getStatus(responsavel.status).color">
                      {{ getStatus(responsavel.status).text }}
                    </b-badge>
                  </td>
                  <td>{{ responsavel.empresa.nome }}</td>
                  <td>{{ responsavel.empresa.cpfcnpj }}</td>
                  <td>{{ responsavel.nome }}</td>
                  <td>{{ responsavel.cpf }}</td>
                  <td>{{ responsavel.crea }}</td>
                  <td>
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="goToResponsavel(responsavel.uuid)"
                    >
                      <b-icon-eye-fill class="mr-2" />Visualizar</b-button
                    >
                  </td>
                  <td>
                    <b-button
                      size="sm"
                      variant="success"
                      @click="
                        updateResponsavel(responsavel.idResponsavel, 'ACTIVE')
                      "
                      ><b-icon-check class="mr-2" />Aprovar</b-button
                    >
                  </td>
                  <td>
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="
                        updateResponsavel(responsavel.idResponsavel, 'DENIED')
                      "
                      >Reprovar <b-icon-x class="mr-2" />
                    </b-button>
                  </td>
                  <td>
                    <b-button
                      @click="deleteResponsavel(responsavel.idResponsavel)"
                      size="sm"
                      variant="secondary"
                      ><b-icon-trash></b-icon-trash
                    ></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card>

          <section v-if="payloadMaquinas.maquinas.length" class="customCard">
            <p class="titleHeaderCustomCard">Maquinas vinculadas</p>

            <hr style="margin: 0" />

            <div
              class="contentMachines"
              v-for="(maquina, idx) in payloadMaquinas.maquinas"
              :key="idx"
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  padding: 1rem;
                "
              >
                <p style="margin: 0; font-weight: 600">{{ maquina.nome }}</p>

                <div style="display: flex; align-items: center; gap: 0.3rem">
                  <label
                    style="margin: 0; font-weight: 600"
                    :for="`checkbox-${idx}`"
                    >Habilitar</label
                  >
                  <input
                    :id="`checkbox-${idx}`"
                    style="width: 15px; height: 15px"
                    type="checkbox"
                    v-model="maquina.ativo"
                  />
                </div>
              </div>
            </div>

            <div class="customCardFooter">
              <button @click="updateMachines">Atualizar maquinas</button>
            </div>
          </section>
        </b-tab>

        <b-tab title="Mensageria">
          <b-card>
            <h3 class="text-center">Mensageria Winfit</h3>

            <b-card>
              <b-row class="col-4">
                <b-form-group class="col-sm-12">
                  <template #label>
                    <label for="" class="mr-3"
                      >Empresa
                      <b-icon-search
                        class="ml-2"
                        variant="primary"
                        @click="openModals('modalForMessages')"
                      ></b-icon-search
                    ></label>
                    <b-button
                      size="sm"
                      pill
                      variant="primary"
                      @click="listClientes"
                      class="float-right"
                      ><b-icon-arrow-repeat></b-icon-arrow-repeat
                    ></b-button>
                  </template>
                  <b-form-select
                    :options="clientes"
                    v-model="empresa.uuid"
                    size="sm"
                    value-field="uuid"
                    text-field="nome"
                  ></b-form-select>
                </b-form-group>

                <b-row class="ml-3" style="gap: 10px">
                  <label>Tipo de mensagem:</label>
                  <b-form-group>
                    <b-button
                      :variant="
                        statusMensagem === messageStatusEnum.SUCCESS
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="statusMensagem = 'SUCCESS'"
                      >Sucesso</b-button
                    >
                  </b-form-group>
                  <b-form-group>
                    <b-button
                      :variant="
                        statusMensagem === messageStatusEnum.WARNING
                          ? 'warning'
                          : 'outline-warning'
                      "
                      @click="statusMensagem = 'WARNING'"
                    >
                      Aviso</b-button
                    >
                  </b-form-group>
                  <b-form-group>
                    <b-button
                      :variant="
                        statusMensagem === messageStatusEnum.PROBLEM
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="statusMensagem = 'PROBLEM'"
                      >Problema</b-button
                    >
                  </b-form-group>
                </b-row>
              </b-row>
              <b-row class="ml-3">
                <b-form-textarea
                  id="textarea"
                  v-model="text"
                  placeholder="Mensagem para o cliente ..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-row>
              <b-row class="ml-3 mt-3">
                <b-button @click="sendMessageWinfit" variant="primary"
                  >Enviar</b-button
                >
              </b-row>
            </b-card>
          </b-card>
        </b-tab>
      </b-tabs>

      <modalCliente
        :idModal="'modalForMessages'"
        @clienteSelecionado="empresa.uuid = $event"
      />
      <modalCliente
        :idModal="'modalForResp'"
        @clienteSelecionado="nameOrCpf = $event"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuBar from "../../components/menu.vue";
import { http } from "../../services/config";
import {
  deleteResponsavel,
  findByNameOrCpf,
  findByParams,
  updateStatus,
  getResposableByParams,
} from "../../services/ServiceResponsavelWinfit";
import { genericUpdate } from "../../services/ServiceActivation";
import { sendMessages } from "../../services/ServiceWinfitCore";
import modalCliente from "../../components/Modals/modal-search-cliente.vue";
// import socket from "../../services/socket";

export default {
  components: {
    MenuBar,
    modalCliente,
  },
  data() {
    return {
      selected: null,
      text: "",
      tabIndex: 0,
      statusMensagem: "SUCCESS",
      users: [],
      userSelect: {},
      keys: [{ key: "username" }],
      fields: [{ titulo: "Nome do Usuário" }, { titulo: "Editar" }],
      responsaveis: [],
      payloadMaquinas: {
        idActivation: "",
        maquinas: "",
      },
      nameOrCpf: "",
      statusFilter: "PENDING",
      empresa: { uuid: null },
      options: [
        { text: "Sucesso", value: "radio1" },
        { text: "Atendção", value: "radio2" },
        { text: "Problema", value: "radio3", disabled: true },
      ],
      messageStatusEnum: {
        SUCCESS: "SUCCESS",
        WARNING: "WARNING",
        PROBLEM: "PROBLEM",
      },
      optionsFilter: [
        { value: null, text: "Filtro" },
        { value: { type: "selectbox", field: "idEmpresa" }, text: "Empresa" },
        { value: { type: "text", field: "cidade" }, text: "Cidade" },
        { value: { type: "text", field: "UF" }, text: "Estado" },
        {
          value: { type: "date", field: "vrn" },
          text: "Vencimento de certificado",
        },
        {
          value: { type: "date", field: "data_nascimento" },
          text: "Data de nascimento",
        },
        {
          value: { type: "date", field: "datalicenca" },
          text: "Data da licença",
        },
      ],
    };
  },
  async mounted() {
    await this.getResposaveisByStatus();
    this.listClientes();
  },
  computed: {
    ...mapState({
      clientes: (state) => state.clientes,
    }),
  },
  methods: {
    async sendMessageWinfit() {
      try {
        await sendMessages({
          client_id: this.empresa.uuid,
          message: this.text,
          status: this.statusMensagem,
        });
      } catch (error) {
        this.$toast.open({
          message: "erro ao enviar mensagem",
          type: "error",
        });
      }
    },

    openModals(nameModal) {
      this.$bvModal.show(nameModal);
    },

    async listClientes() {
      try {
        const url = `/customers/list/combobox`;
        const { data } = await http.get(url);
        this.$store.commit("clientes", data);
      } catch (error) {
        this.$toast.open({
          message: "erro na combobox",
          type: "error",
        });
      }
    },
    getStatus(status) {
      const statusEnum = {
        DENIED: "danger",
        ACTIVE: "success",
        PENDING: "primary",
      };

      const statusText = {
        DENIED: "Negado",
        ACTIVE: "Aprovado",
        PENDING: "Pendente",
      };

      return { color: statusEnum[status], text: statusText[status] };
    },
    async getResposaveisByStatus(status = "PENDING") {
      this.statusFilter = status;
      const responsaveis = await findByParams(
        this.statusFilter,
        this.nameOrCpf
      );
      this.responsaveis = responsaveis;
    },
    async resposableByParams() {
      if (!this.selected) {
        return this.$swal(
          "Responável",
          "Selecione um filtro para realizar a pesquisa",
          "error"
        );
      }
      const result = await getResposableByParams(
        this.statusFilter,
        this.selected.field,
        this.nameOrCpf
      );

      this.responsaveis = result.responsaveis;
      this.payloadMaquinas = result.activation;
    },
    goToResponsavel(idResponsavel) {
      console.log(idResponsavel);
      if (!idResponsavel) return;
      this.$router.push(`/responsavel/${idResponsavel}`);
    },
    async editar(nome) {
      try {
        const url = `/users/${nome}`;
        const { data } = await http.get(url);
        this.userSelect = data;
        this.tabIndex = 0;
      } catch (error) {
        this.$swal("Usuário", "erro ao editar Usuário", "error");
      }
    },
    async listUser() {
      try {
        const url = `/users/list/combobox`;
        const { data } = await http.get(url);
        this.users = data;
      } catch (error) {
        this.$swal("Usuário", "erro ao listar Usuário", "error");
      }
    },
    async updateResponsavel(idResponsavel, status) {
      try {
        console.log(idResponsavel, status);
        await updateStatus(idResponsavel, { status });
        await this.getResposaveisByStatus();
        this.$swal("Responável", "Atualizado com sucesso", "success");
      } catch (error) {
        this.$swal("Responável", "erro ao atualizar", "error");
      }
    },

    async deleteResponsavel(idResponsavel) {
      try {
        await deleteResponsavel(idResponsavel);
        await this.getResposaveisByStatus();
        this.$swal("Responável", "Deletado com sucesso", "success");
      } catch (error) {
        this.$swal("Responável", "erro ao deletar", "error");
      }
    },

    async searchByNameOrCpf() {
      try {
        const responsaveis = await findByNameOrCpf(this.nameOrCpf);
        this.responsaveis = responsaveis;
      } catch (error) {
        this.$swal("Responável", "erro ao buscar", "error");
      }
    },

    async updateMachines() {
      try {
        await genericUpdate(
          "maquinas",
          this.payloadMaquinas.maquinas,
          this.payloadMaquinas.uuid
        );

        this.$swal("Maquinas", "Atualizado com sucesso", "success");
      } catch (error) {
        this.$swal("Maquinas", "erro ao atualizar", "error");
      }
    },
  },
};
</script>

<style scoped>
.customCard {
  width: 40%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}

.contentMachines {
  max-height: 300px;
  overflow: auto;
}

.titleHeaderCustomCard {
  padding: 0.5rem;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
}

.customCardFooter {
  padding: 0.3rem;
}

.customCardFooter button {
  border: none;
  outline: none;
  border-radius: 0.25rem;
  background-color: #28a745;
  color: white;
  padding: 0.3rem 0.5rem;
  font-size: 14px;
  transition: ease-in all 0.1s;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.528);
}

.customCardFooter button:hover {
  background-color: #218e3b;
}
</style>
