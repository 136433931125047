<template>
  <div>
    <b-overlay no-wrap opacity="0.95"> </b-overlay>
    <b-row>
      <b-form-group label="Cliente" class="col-sm-3">
        <template #label> Responsável </template>
        <b-input v-model="responsavel.nome" disabled type="text" size="sm">
        </b-input>
      </b-form-group>
      <b-form-group label="CPF" class="col-sm-2">
        <b-input v-model="responsavel.cpf" type="text" disabled size="sm">
        </b-input>
      </b-form-group>
      <b-form-group label="Empresa" class="col-sm-3">
        <b-input
          v-model="responsavel.empresa.nome"
          type="text"
          disabled
          size="sm"
        >
        </b-input>
      </b-form-group>
      <b-form-group label="CNPJ" class="col-sm-2">
        <b-input
          v-model="responsavel.empresa.cpfcnpj"
          type="text"
          disabled
          size="sm"
        >
        </b-input>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group label="CREA/CFTA" class="col-sm-2">
        <b-input v-model="responsavel.crea" type="text" disabled size="sm">
        </b-input>
      </b-form-group>
      <b-form-group label="Endereço" class="col-sm-3">
        <b-input v-model="responsavel.endereco" type="text" disabled size="sm">
        </b-input>
      </b-form-group>
      <b-form-group label="Bairro" class="col-sm-2">
        <b-input v-model="responsavel.bairro" type="text" disabled size="sm">
        </b-input>
      </b-form-group>
      <b-form-group label="Cidade" class="col-sm-2">
        <b-input v-model="responsavel.cidade" type="text" disabled size="sm">
        </b-input>
      </b-form-group>
      <b-form-group label="UF" class="col-sm-1">
        <b-input v-model="responsavel.UF" type="text" disabled size="sm">
        </b-input>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group label="Telefone" class="col-sm-2">
        <b-input v-model="responsavel.telefone" type="text" disabled size="sm">
        </b-input>
      </b-form-group>

      <b-form-group label="Email" class="col-sm-2">
        <b-input v-model="responsavel.email" type="text" disabled size="sm">
        </b-input>
      </b-form-group>

      <b-form-group label="Senha certificado" class="col-sm-2">
        <b-input v-model="responsavel.senhacertificado" type="text" size="sm">
        </b-input>
      </b-form-group>

      <b-form-group label="Habilitar assinatura digital" class="col-sm-2">
        <b-form-select
          :options="habilitarAssinatura"
          v-model="responsavel.habilitarassinatura"
          size="sm"
          class=""
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Status" class="col-sm-1">
        <h4>
          <b-badge :variant="getStatus(responsavel.status).color">{{
            getStatus(responsavel.status).text
          }}</b-badge>
        </h4>
      </b-form-group>
    </b-row>
    <div style="width: 50%; margin: 40px auto">
      <h4 class="text-center p-4">Certificado</h4>
      <table class="table table-sm table-outlined">
        <thead>
          <tr>
            <th scope="col">Status</th>
            <th scope="col">Nome responsável</th>
            <th scope="col">Data de emissão</th>
            <th scope="col">Data de vencimento</th>
            <th scope="col">Download</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <b-form-group>
      <b-button
        class="mr-2"
        size="sm"
        variant="primary"
        @click="updateResponsavel"
      >
        <b-icon-check-circle class="mr-2" />Atualizar
      </b-button>
      <b-button
        class="mr-2"
        size="sm"
        variant="success"
        @click="updateStatusResponsavel(responsavel.idResponsavel, 'ACTIVE')"
        ><b-icon-check class="mr-2" />Aprovar</b-button
      >
      <b-button
        size="sm"
        variant="danger"
        @click="updateStatusResponsavel(responsavel.idResponsavel, 'DENIED')"
        >Reprovar
        <b-icon-x class="mr-2" />
      </b-button>
    </b-form-group>

    <!-- <modalCliente @clienteSelecionado="agendamento.clienteId = $event" />
                                        <modalInfoClientes :idCliente="this.idClienteSelecionado" /> -->
    <!-- MODAL NÃO USADO -->
    <!-- <b-modal id="modal-lg-12" size="xl" title="Histórico deee Suporte">
                                          <b-tabs pills card>
                                            <b-tab title="Histórico" active>
                                              <div class="containerTable">
                                                <Table :keys="keys" :fields="fields" :dataProps="history">
                                                  <template #buttonAcoes="{ uuid }">
                                                    <b-button
                                                      variant="primary"
                                                      pill
                                                      size="sm"
                                                      @click="editarAgendamento(uuid)"
                                                      ><b-icon-eye-fill></b-icon-eye-fill> Visualizar</b-button
                                                    >
                                                  </template>
                                                </Table>
                                              </div>
                                            </b-tab>
                                            <b-tab title="Histórico Gestão Legado">
                                              <div class="containerTable">
                                                <table class="table table-sm">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Nome Cliente</th>
                                                      <th scope="col">CNPJ/CPF</th>
                                                      <th scope="col">Data Agendamento</th>
                                                      <th scope="col">Data Início</th>
                                                      <th scope="col">Data Fim Atendimento</th>
                                                      <th scope="col">Nome Pessoa Atendida</th>
                                                      <th scope="col">Técnico</th>
                                                      <th scope="col">Observação</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      v-for="(histoLeg, index) in historicoDataLegado"
                                                      :key="index"
                                                    >
                                                      <th>{{ histoLeg.nome }}</th>
                                                      <td>{{ histoLeg.cpfcnpj }}</td>
                                                      <td>{{ histoLeg.dataagendamento }}</td>
                                                      <td>{{ histoLeg.datainicioatendimento }}</td>
                                                      <td>{{ histoLeg.datafimatendimento }}</td>
                                                      <td>{{ histoLeg.nomepessoaatendimento }}</td>
                                                      <td>{{ histoLeg.username }}</td>
                                                      <td>{{ histoLeg.observacao }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </b-tab>
                                          </b-tabs>
                                        </b-modal> -->
  </div>
</template>

<script>
import { findById } from "../../services/ServiceResponsavelWinfit";
import { updateStatus, update } from "../../services/ServiceResponsavelWinfit";
// import Table from "../Table/Table.vue";

// import modalCliente from "../Modals/modal-search-cliente.vue";
// import modalInfoClientes from "../Modals/modal-info-cliente.vue";

export default {
  props: {
    saveResp: {
      typeof: Function,
    },
  },
  components: {
    // modalCliente,
    // Table,
    // modalInfoClientes,
  },
  data() {
    return {
      responsavel: {
        uuid: null,
        idResponsavel: null,
        idEmpresa: null,
        nome: "",
        cpf: "",
        empresa: "",
        cnpj: "",
        crea: "",
        endereco: "",
        bairro: "",
        cidade: "",
        uf: "",
        telefone: "",
        email: "",
        status: "",
        senhacertificado: "",
        habilitarassinatura: false,
      },
      habilitarAssinatura: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
    };
  },
  async mounted() {
    console.log(this.$route.params.idResponsavel);
    if (!this.$route.params.idResponsavel) return;
    const data = await findById(this.$route.params.idResponsavel);

    Object.assign(this.responsavel, data);
  },
  methods: {
    getStatus(status) {
      const statusEnum = {
        INACTIVE: "warning",
        ACTIVE: "success",
        PENDING: "primary",
        DENIED: "danger",
      };

      const statusText = {
        INACTIVE: "Inativo",
        ACTIVE: "Aprovado",
        PENDING: "Pendente",
        DENIED: "Negado",
      };

      return { color: statusEnum[status], text: statusText[status] };
    },
    async updateStatusResponsavel(idResponsavel, status) {
      try {
        await updateStatus(idResponsavel, { status });
        this.responsavel.status = status;
        this.$swal("Responsável", "Atualizado com sucesso", "success");
      } catch (error) {
        this.$swal("Responsável", "erro ao atualizar", "error");
      }
    },

    async updateResponsavel() {
      try {
        await update(this.responsavel, this.responsavel.uuid);
        this.$swal("Responsável", "Atualizado com sucesso", "success");
      } catch (error) {
        this.$swal("Responsável", "erro ao atualizar", "error");
      }
    },
  },
};
</script>

<style>
.containerTable {
  max-height: 400px;
  overflow: auto;
}
</style>