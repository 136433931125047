<template>
  <div>
    <div class="row" style="margin-bottom: 10px">
      <b-form class="col-sm-12 row">
        <b-form-group label="Nome" class="col-sm-5">
          <b-form-input
            type="text"
            v-model="responsible.nome"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="CPF" class="col-sm-2">
          <b-form-input
            type="text"
            size="sm"
            v-mask="'###.###.###-##'"
            v-model="responsible.cpf"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="CREA" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="responsible.crea"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="CEP" class="col-sm-2">
          <b-form-input
            v-b-tooltip.focus.top
            title="Digite o Cep e presione ENTER para buscar"
            type="text"
            size="sm"
            v-mask="'#####-###'"
            v-model="responsible.cep"
            @keyup.enter="findCityByCep"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Endereço" class="col-sm-4">
          <b-form-input
            type="text"
            v-model="responsible.endereco"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nº" class="col-sm-1">
          <b-form-input
            type="text"
            v-model="responsible.numero"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Bairro" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="responsible.bairro"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Cidade" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="responsible.cidade"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="UF" class="col-sm-1">
          <b-form-input
            type="text"
            v-model="responsible.uf"
            size="sm"
            maxlength="2"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Telefone" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="responsible.telefone"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email" class="col-sm-6">
          <b-form-input
            type="email"
            v-model="responsible.email"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </div>
    <b-button type="submit" size="sm" variant="info" @click="handleSaveOrUpdate"
      >Salvar</b-button
    >
    <b-button
      type="reset"
      size="sm"
      variant="info"
      style="margin-left: 5px"
      @click="clear"
    >
      Limpar
    </b-button>
  </div>
</template>

<script>
import {
  saveResponsible,
  updateResponsible,
} from "../../services/ServiceResponsible";
import { findCep } from "../../services/ServiceCep";

export default {
  props: {
    idEmpresa: {
      type: String,
    },
    resp: {
      type: Object,
    },
  },
  data() {
    return {
      responsible: {
        id_responsavel: "",
        nome: "",
        cpf: "",
        crea: "",
        endereco: "",
        bairro: "",
        numero: "",
        cidade: "",
        uf: "",
        cep: "",
        telefone: "",
        email: "",
      },
    };
  },
  methods: {
    async save() {
      try {
        if (this.validate(this.responsible)) {
          return this.$toast.open({
            message: "Todos os campos devem ser preenchidos",
            type: "warning",
          });
        }

        const resp = await saveResponsible(this.responsible, this.idEmpresa);
        this.responsible.id_responsavel = resp.id_responsavel;

        return this.$toast.open({
          message: "Registro salvo com sucesso",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao salvar o responsável",
          type: "error",
        });
      }
    },

    async update() {
      try {
        await updateResponsible(this.responsible, this.idEmpresa);

        return this.$toast.open({
          message: "Registro Atualizado com sucesso",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao atualizar o responsável",
          type: "error",
        });
      }
    },

    handleSaveOrUpdate() {
      this.responsible.id_responsavel !== "" ? this.update() : this.save();
    },

    validate(responsible) {
      const result = Object.keys(responsible).find((item) => {
        if (item !== "id_responsavel") {
          return !responsible[item];
        }
      });

      return result;
    },

    async findCityByCep() {
      try {
        const result = await findCep(this.responsible.cep);

        this.responsible = {
          ...this.responsible,
          bairro: result.bairro,
          endereco: result.logradouro,
          uf: result.uf,
          cidade: result.localidade,
          cep: result.cep,
        };
      } catch (error) {
        return this.$toast.open({
          message: error.message,
          type: "error",
        });
      }
    },

    compareReponsibleObject(target, source) {
      const newResp = {};
      Object.keys(target).forEach((item) => {
        if (item in source) {
          newResp[item] = source[item];
        }
      });

      this.responsible = newResp;
    },

    clear() {
      this.responsible = {
        id_responsavel: "",
        nome: "",
        cpf: "",
        crea: "",
        endereco: "",
        bairro: "",
        numero: "",
        cidade: "",
        uf: "",
        cep: "",
        telefone: "",
        email: "",
      };
    },
  },
  watch: {
    resp() {
      this.compareReponsibleObject(this.responsible, this.resp);
    },
  },
};
</script>

<style>
</style>