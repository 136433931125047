import { http } from "./config"

export const saveResponsible = async (dataResponsible, idEmpresa) => {
  const { data: { result } } = await http.post(`/winfit/empresa/${idEmpresa}`, dataResponsible)
  return result
}

export const updateResponsible = async (dataResponsible, idEmpresa) => {
  console.log(dataResponsible)
  const { data: { result } } = await http.put(`/winfit/empresa/${idEmpresa}`, dataResponsible)
  return result
}

export const findResponsibles = async (idEmpresa) => {
  const { data: { resposibles } } = await http.get(`/winfit/empresa/${idEmpresa}`)
  return resposibles
}

export const findResponsibleById = async (idEmpresa, idResp) => {
  const { data: { resposibles } } = await http.get(`/winfit/empresa/${idEmpresa}/${idResp}`)
  return resposibles
}