import Vue from 'vue'
import Router from 'vue-router'
import Software from './pages/Softwares/page-software.vue'
import Cliente from './pages/cliente/cliente.vue'
import Liberacoes from './pages/Liberacoes/page-liberacoes.vue'
import Home from './pages/home.vue'
import Agenda from './pages/Agenda/page-agenda.vue'
import Login from './components/forms/FormLogin.vue'
import Log from './pages/Logs/Logs.vue'
import User from './pages/User/user.vue'
import Responsavel from './pages/Responsavel/responsavel.vue'
import ResponsavelId from './pages/ResponsavelId/responsavelId.vue'
import BlogPage from './pages/Blog/page-blog.vue'

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/user',
        component: User,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/log',
        component: Log,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/software',
        component: Software,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cliente',
        component: Cliente,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/liberar',
        component: Liberacoes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/agenda',
        component: Agenda,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/responsavel',
        component: Responsavel,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/responsavel/:idResponsavel',
        component: ResponsavelId,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blog',
        component: BlogPage,
        meta: {
            requiresAuth: true
        }
    },
]


const router = new Router({
    mode: 'history',
    routes
})


function claudiaValidations(to, next) {
    const user = sessionStorage.getItem("username");
    
    if (to.path === "/agenda" && user === "claudia") {
        return next("/software")
    }
    if (to.path === "/liberar" && user === "claudia") {
        return next("/software")
    }
    if (to.path === "/cliente" && user === "claudia") {
        return next("/software")
    }
    if (to.path === "/user" && user === "claudia") {
        return next("/software")
    }
    if (to.path === "/Home" && user === "claudia") {
        return next("/software")
    }
    return next()
}

router.beforeEach(async (to, from, next) => {
    claudiaValidations(to, next)
    if (to.path == '/') {
        return next()
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!sessionStorage.getItem('token')) {
            return next({
                path: '/',
            })
        }

        return next()
    }

    return next({ path: '/' })
})

export default router;