<template>
  <div>
    <MenuBar />
    <b-button class="ml-4" variant="primary" @click="openModal"><b-icon-plus></b-icon-plus> Criar post</b-button>
    <b-modal id="modal-lg-12" size="xl" title="Criar post">
      <b-card>
        <b-row>
          <b-form-group label="Título" class="col-sm-6">
            <b-input v-model="postBlog.title" type="text" size="sm"> </b-input>
          </b-form-group>
          <b-form-group label="Subtitulo" class="col-sm-6">
            <b-input v-model="postBlog.subtitle" type="text" size="sm"> </b-input>
          </b-form-group>
        </b-row>
  
        <b-row>
          <b-form-group label="Conteúdo" class="col-sm-12">
            <b-form-textarea v-model="postBlog.content" id="textarea" placeholder="Enter something..." rows="3"
              max-rows="6"></b-form-textarea>
          </b-form-group>
        </b-row>
  
        <b-row>
          <b-form-group label="Imagem" class="col-sm-7">
            <b-form-file v-model="postBlog.cover" browse-text="Procurar imagem"
              placeholder="Selecione a imagem de capa"></b-form-file>
          </b-form-group>
          <b-form-group label="Notificar usuários">
            <b-form-checkbox v-model="notificar" switch size="md">{{notificar ? 'Notificação Ativa' :
              'Notificação desativada'}}</b-form-checkbox>
          </b-form-group>
        </b-row>
      </b-card>
      <template #modal-footer>
        <div class="w-100">
          <b-button @click="savePost" variant="success" size="sm" class="float-right">
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="container-posts">
      <div class="container-card" v-for="post in postsBlog" :key="post.uuid">
        <b-row class="p-3">


          <b-icon-eye-fill @click="editPost(post)" style="cursor: pointer;" class="h3 mr-4"></b-icon-eye-fill>
          <b-icon-trash style="cursor: pointer;" @click="deletePost(post.uuid)" class="h3 mr-4"></b-icon-trash>
          <b-form-checkbox v-model="checked" size="lg" name="check-button" switch>
            Publicar post
            </b-form-checkbox>
        </b-row>
        <BlogCard :post="post" />
      </div>
  
    </div>
  </div>
</template>

<script>
import BlogCard from "../../components/Blog/BlogCard.vue";
import MenuBar from "../../components/menu.vue";
import { showAll, save, deletePost, update } from '../../services/ServiceBlog'

export default {
  components: { BlogCard, MenuBar },
  data() {
    return {
      postsBlog: [],
      notificar: false,
      postBlog: {
        uuid: null,
        title: null,
        subtitle: null,
        content: null,
        cover:
          "https://www.tactus.com.br/wp-content/uploads/2020/08/financeira-site.png",
      },
    };
  },
  mounted() {
    this.showAllPosts();
  },
  methods: {
    openModal() {
      this.$bvModal.show("modal-lg-12");
    },
    async showAllPosts() {
      const { data } = await showAll()

      this.postsBlog = data

    },
    async savePost() {
      if (this.postBlog.uuid) {
        await update(this.postBlog, this.postBlog.uuid)
        this.$toast.open({
          message: "Post atualizado com sucesso!",
          type: "success",
          // all of other options may go here
        });
        await this.showAllPosts()
        this.$bvModal.hide("modal-lg-12");

        return
      }
      await save(this.postBlog)
      this.$bvModal.hide("modal-lg-12");
      this.$toast.open({
        message: "Post salvo com sucesso!",
        type: "success",
        // all of other options may go here
      });
      this.showAllPosts()
    },
    async deletePost(uuid) {
      await deletePost(uuid)
      this.showAllPosts()
      this.$toast.open({
        message: "Post deletado com sucesso!",
        type: "success",
        // all of other options may go here
      });
    },

    async editPost(post) {
      this.postBlog = post
      this.$bvModal.show("modal-lg-12");
    }

  },
};
</script>

<style scoped>
.container-posts {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 100px;
}

.container-card {
  padding: 0 0 0 75px;
  height: fit-content;
}
</style>
