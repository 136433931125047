<template>
    <div>
    
        <div class="blog_post">
            <div class="img_pod">
                <img :src="post.cover" alt="random image">
            </div>
            <div class="container_copy">
                <h1>{{post.title}}</h1>
                <h2>{{post.subtitle}}</h2>
                <p>{{ contentShow }}</p>
                <h3>Publicado em: {{ new
                    Date(post.created_at).toISOString().split("T")[0].split("-").reverse().toLocaleString().replace(',',
                    '/').replace(',', '/')}}</h3>
            </div>
            <!-- <a class="btn_primary" href='#' target="_blank">Read More</a> -->
    
        </div>
    </div>
</template>

<script>
export default {
  name: 'BlogCard',
  props: {
    post: {
      type: Object,
      required: true
    }

  },
  computed: {
    contentShow() {
      if (this.post.content.length > 160) {
        return this.post.content.substring(0, 160) + '...'
      }
      return this.post.content
    }
  },
}
</script>

<style scoped>
.blog_post {
    background: #fff;
    width: 440px;
    height: 330px;
    border-radius: 10px;
    position: relative;
    transition: .3s;
    display: flex;
    flex-direction: column;
   align-items: flex-end;
}

.blog_post:hover {
    box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
}


.container_copy {
    padding: 2rem 2rem 0rem 12rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.img_pod {
    width: 250px;
    height: 310px;
    background: #11998e;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #38ef7d, #11998e);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #38ef7d, #11998e);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    z-index: 10;
    box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    position: absolute;
    left: -20%;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h2 {
    color: #6e6e6e;
    font-size: 14px;
    height: 20px;
}

img {
    width: 230px;
    height: 290px;
    position: relative;
    border-radius: 10px;
    box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
    z-index: 1;
    
}

h3 {
    color: #999;
    font-size: 13px;

}

h1 {
    font-size: 20px;
    letter-spacing: 0.5px;
    color: #666;
    font-weight: 600;
    height: 50px;
}

p {
    font-size: 15px;
    line-height: 1.45;
    color: #333;
    height: 130px;
}

.btn_primary {
    border: none;
    outline: none;
    background: #11998e;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #38ef7d, #11998e);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #38ef7d, #11998e);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 30%;
    border-radius: 50px;
    color: white;
    font-size: 15px;
    box-shadow: 1px 10px 2rem rgba(255, 94, 98, 0.5);
    transition: all 0.2s ease-in;
    text-decoration: none;
    position: absolute;
    bottom: 1px;
    right: 90px;
}

.btn_primary:hover {
    box-shadow: 0px 5px 1rem rgba(255, 94, 98, 0.5);
}

@media only screen and (max-width: 650px) {
    body {
        background-color: black;
    }

    .img_pod {}
}
</style>