import axios from "axios";

export const findCep = async (cep) => {
  const formatedCep = cep.replace("-", "");

  const { data } = await axios.get(
    `https://viacep.com.br/ws/${formatedCep}/json/`
  );

  if("erro" in data) {
    throw Error("Cep não encontrado")
  }
  
  return data
}