import { http } from "./config";

export const save = async (dataResponsible) => {
  const {
    data: { result },
  } = await http.post("/responsavel-winfit", dataResponsible);
  return result;
};

export const deleteResponsavel = async (idResponsavel) => {
  const data = await http.delete(`/responsavel-winfit/${idResponsavel}`);
  return data;
};

export const update = async (dataResponsible, idResponsavel) => {
  const { data } = await http.put(
    `/responsavel-winfit/${idResponsavel}`,
    dataResponsible
  );
  return data;
};

export const findById = async (idResponsavel) => {
  const { data } = await http.get(`/responsavel-winfit/${idResponsavel}`);
  return data;
};

export const findByParams = async (status, nameOrCpf) => {
  const { data } = await http.get(
    `/responsavel-winfit/resposavel/findByParams?status=${status}&nameOrCpf=${nameOrCpf}`
  );
  return data;
};

export const updateStatus = async (idResponsavel, status) => {
  const { data } = await http.put(
    `/responsavel-winfit/${idResponsavel}/status`,
    status
  );
  return data;
};

export const findByNameOrCpf = async (nameOrCpf) => {
  const { data } = await http.get(
    `/responsavel-winfit/responsavel/${nameOrCpf}`
  );
  console.log(data, "service");
  return data;
};

export const getResposableByParams = async (status, field, value) => {
  const { data } = await http.get(
    `/responsavel-winfit/solicitations/by-params?status=${status}&field=${field}&value=${value}`
  );
  return data;
};
