import axios from 'axios'

export const http = axios.create({
    baseURL: process.env.VUE_APP_URL_BACKEND,
})

http.interceptors.response.use(response => {
    return response;
}, error => {
   
    if (error.response.status === 401) {
        sessionStorage.removeItem("setor")
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("username")
        window.location.href = process.env.VUE_APP_URL_FRONT
    }

    return Promise.reject(error)
});

http.interceptors.request.use((value) => {
    value.headers = {
        ...value.headers,
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }

    return value
})
