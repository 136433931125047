<template>
  <div>
    <MenuBar class="mb-4" />
    <div v-if="show === false" class="home">
      <b-card-group deck class="m-1">
        <b-card
          bg-variant="info"
          style="border-radius: 5px"
          text-variant="white"
          class="text-center shadow"
        >
          <template #header
            >Agendamentos em Espera
            <b-icon-clock scale="1.5" class="ml-3"></b-icon-clock
          ></template>
          <h3>{{ emEspera }}</h3>
        </b-card>

        <b-card
          bg-variant="info"
          style="border-radius: 5px"
          text-variant="white"
          class="text-center shadow"
        >
          <template #header
            >Agendamentos em Atend.<b-icon-hourglass-split
              scale="1.5"
              class="ml-3"
            ></b-icon-hourglass-split
          ></template>
          <h3>{{ emAtendimento }}</h3>
        </b-card>

        <b-card
          bg-variant="info"
          style="border-radius: 5px"
          text-variant="white"
          class="text-center shadow"
        >
          <template #header
            >Agendados Hoje<b-icon-person-plus-fill
              scale="1.5"
              class="ml-3"
            ></b-icon-person-plus-fill
          ></template>
          <h3>{{ agendadosHoje }}</h3>
        </b-card>

        <b-card
          bg-variant="info"
          style="border-radius: 5px"
          text-variant="white"
          class="text-center shadow"
        >
          <template #header
            >Baixas totais (Hoje)<b-icon-arrow-down
              scale="1.5"
              class="ml-3"
            ></b-icon-arrow-down
          ></template>
          <h3>{{ baixasHoje }}</h3>
        </b-card>

        <b-card
          bg-variant="info"
          style="border-radius: 5px"
          text-variant="white"
          class="text-center shadow"
        >
          <template #header
            >Minhas baixas
            <b-icon-person-lines-fill scale="1.5" class="ml-3">
              ></b-icon-person-lines-fill
            >
            <b-icon-arrow-down scale="1.5" class="ml-3"></b-icon-arrow-down
          ></template>
          <h3>{{ baixasUser }}</h3>
        </b-card>
      </b-card-group>
    </div>
    <div class="p-3" v-if="show === false">
      <b-card>
        <h4 class="text-center">Relatório de Atendimentos</h4>
        
        <b-tabs class="tabsTab">
          <b-tab
            title-link-class="text-dark"
            v-for="(atendimento, index) in relatorioAtendimento"
            :key="index"
            :title="index"
          >
            <div class="atendimento p-1">
              <table class="table table-sm table-hover table-dark mt-3">
                <thead>
                  <tr>
                    <th scope="col">Nome Cliente</th>
                    <th scope="col">Status</th>
                    <th scope="col">Tempo de Atendimento</th>
                    <th scope="col">Tipo de Suporte</th>
                    <th scope="col">Tempo Maximo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(atendi, index) in atendimento" :key="index">
                    <td>{{ atendi.nomeCliente }}</td>
                    <td>{{ atendi.status }}</td>
                    <td>{{ atendi.tempo }}</td>
                    <td>{{ atendi.tipoSuporte }}</td>
                    <td>{{ atendi.tempoMaximo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
        <b-button size="sm" variant="info" class="mt-2" :disabled=downloadPDF @click="geraRelatorio">Gerar Relatório PDF</b-button>
      </b-card>
    </div>
    <b-overlay no-wrap :show="show" opacity="0.05"> </b-overlay>

    <div
      v-if="show === false"
      class="baixas"
      style="display: flex; flex-wrap: wrap"
    >
      <div class="col-sm-3">
        <b-card
          class="text-center cardBaixa col-sm-12 shadow"
          title="Baixas totais de Hoje"
        ></b-card>
        <b-card class="text-center cardBaixa col-sm-12 shadow">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th scope="col">Atendente</th>
                <th scope="col">Baixas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="baixa in baixasAllUsers" :key="baixa.nome">
                <td>{{ baixa.nome }}</td>
                <td>
                  <h5>
                    <b-badge
                      variant="danger"
                      class="ml-2"
                      v-if="baixa.baixas <= 5"
                      >{{ baixa.baixas }} Baixas</b-badge
                    >
                    <b-badge
                      variant="warning"
                      class="ml-2"
                      v-else-if="baixa.baixas > 5 && baixa.baixas <= 8"
                      >{{ baixa.baixas }} Baixas</b-badge
                    >
                    <b-badge
                      variant="success"
                      class="ml-2"
                      v-else-if="baixa.baixas > 8"
                      >{{ baixa.baixas }} Baixas
                    </b-badge>
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="rowBaixa">
        <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
         <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
         <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
         <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
        
      </div> -->
        </b-card>
      </div>
      <div class="col-sm-6 mt-4 charGrafic">
        <!-- <LineChart v-if="load" :baixaSemanal="baixaSemanal" /> -->
        <b-card>
          <h5 class="text-center mb-3">Relatório de Login</h5>
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Funcionário</th>
                <th scope="col">Horário de Login</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody v-for="relat in relatLogin" :key="relat.username">
              <tr
                :class="
                  relat.atrasado === true
                    ? 'bg-danger text-white'
                    : 'bg-success text-white'
                "
              >
                <td>{{ relat.username }}</td>
                <td>{{ relat.horaLogin }}</td>
                <td v-if="relat.atrasado">
                  Atrasado <b-icon-hand-thumbs-down></b-icon-hand-thumbs-down>
                </td>
                <td v-else>
                  Em Ponto <b-icon-hand-thumbs-up></b-icon-hand-thumbs-up>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>
      <div class="col-sm-3">
        <b-card
          class="text-center cardBaixa col-sm-12 shadow"
          title="Baixas totais da Semana"
        ></b-card>
        <b-card
          class="text-center cardBaixa col-sm-12 shadow"
        >
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th scope="col">Atendente</th>
                <th scope="col">Baixas</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(baixa, index) in baixaSemanalAllUser"
                :key="baixa.nome"
              >
                <td>{{ baixa.username }}</td>
                <td>
                  <h5>
                    <b-badge variant="success" v-if="index === 0"
                      >{{ baixa.quantidade }} Baixas
                    </b-badge>
                    <b-badge variant="info" v-else
                      >{{ baixa.quantidade }} Baixas
                    </b-badge>
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="rowBaixa">
        <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
         <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
         <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
         <div>
           <h2><b-badge variant="info" >Usuario : 12 Baixas</b-badge> </h2>
        </div>
        
      </div> -->
        </b-card>
      </div>
      <div class="col-sm-8 charGrafic" style="margin: 0 auto">
        <LineChart v-if="load" :baixaSemanal="baixaSemanal" />
      </div>
    </div>
    
    <Pdf v-show="false" :relatorioBaixas=baixasAllUsers :relatorioAtendimento="relatorioAtendimentoPDF" />
  </div>
</template>

<script>
import Pdf from '../components/Pdf/Pdf.vue'
import MenuBar from "../components/menu.vue";
import { http } from "../services/config";
import LineChart from "../services/chart.vue";

export default {
  name: "home",
  props: {},
  components: {
    MenuBar,
    LineChart,
    Pdf
  },
  computed: {},

  data() {
    return {
      load2: false,
      relatorioAtendimento: {},
      relatorioAtendimentoPDF: {},
      mediaAtendimento: 0,
      maiorBaixa: [],
      show: false,
      loader: "bars",
      isLoading: false,
      fullPage: true,
      baixaSemanal: [],
      baixaSemanalAllUser: [],
      emEspera: "",
      emAtendimento: "",
      agendadosHoje: "",
      baixasHoje: "",
      baixasUser: "",
      datacollection: null,
      load: false,
      baixasAllUsers: [],
      relatLogin: [],
      downloadPDF: false
    };
  },

  async created() {
    this.show = true;
    await this.relatorioAtendimentos();
    await this.listClientes();
    await this.listSoftware();
    await this.countAgendamento();
    await this.baixas();
    await this.baixaSemana();
    await this.relatorioHorario();
    this.show = false;
  },

  methods: {
    geraRelatorio(){
      this.relatorioAtendimentoPDF = this.relatorioAtendimento
      this.downloadPDF = true
    },
    async relatorioHorario() {
      try {
        const url = `/logger/login`;
        const { data } = await http.get(url);
        this.relatLogin = data;
      } catch (error) {
        console.log(error)
      }
    },
    async relatorioAtendimentos() {
      try {
        const url = `/schedules/report/users`;
        const { data } = await http.get(url);
        this.relatorioAtendimento = data;
        for (const key in  this.relatorioAtendimento) {
           this.relatorioAtendimento[key].forEach((element) => {
          switch (element.tipoSuporte) {
            case "ERROSISTEMA":
              element.tipoSuporte = "Erro Sistema";
              break;
            case "ERRONFE":
              element.tipoSuporte = "Erro NF-e";
              break;
            case "ERROCONFIGURACAO":
              element.tipoSuporte = "Erro Configuração";
              break;
            case "ERRONFCE":
              element.tipoSuporte = "Erro NFC-e";
              break;
            case "ERROMDF":
              element.tipoSuporte = "Erro MDF-e";
              break;
            case "ERRO_SISTEMA_NAO_ABRE":
              element.tipoSuporte = "Erro Sistema não Abre";
              break;
            case "ERRO_SISTEMA_PROGRAMACAO":
              element.tipoSuporte = "Erro Sistema Programação";
              break;
            case "RELATORIO_GO":
              element.tipoSuporte = "Relatório - GO";
              break;
            case "RELATORIO_RS":
              element.tipoSuporte = "Relatório - RS";
              break;
            case "RELATORIO_ES":
              element.tipoSuporte = "Relatório - ES";
              break;
            case "RELATORIO_MT":
              element.tipoSuporte = "Relatório - MT";
              break;
            case "RELATORIO_SC":
              element.tipoSuporte = "Relatório - SC";
              break;
            default:
              break;
          }
        });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async baixas() {
      try {
        const baixas = [];
        const url = `/schedules/report/schedules-users/${
          new Date().toISOString().split("T")[0]
        }`;

        const { data } = await http.get(url);
        Object.keys(data).forEach(function (item) {
          baixas.push({ nome: item, baixas: data[item] });
        });

        this.baixasAllUsers = baixas;

        this.baixasAllUsers.sort(function (a, b) {
          if (a.baixas > b.baixas) return -1;
          if (a.baixas < b.baixas) return 1;
          return 0;
        });

      } catch (error) {
        this.$toast.open({
          message: "Erro ao listar baixas",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async countAgendamento() {
      try {
        const url = `/schedules/report/count`;
        const { data } = await http.get(url);
        const media = data.agendamentosDasemana.length / 5;
        this.mediaAtendimento = media;
        this.baixaSemanal = data.agendamentosDasemana;
        this.emEspera = data.agendamentosEmEspera;
        this.emAtendimento = data.agendamentosEmAtendimento;
        this.agendadosHoje = data.agendamentosDoDia;
        this.baixasHoje = data.agendamentosFinalizadoDoDia;
        this.baixasUser = data.agendamentosFinalizadoPorUsuario;
        this.load = true;
      } catch (error) {
        this.$toast.open({
          message: "Erro ao contar agendamentos",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async baixaSemana() {
      try {
        const url = `/schedules/report/week`;
        const { data } = await http.get(url);
        this.baixaSemanalAllUser = data;
        
        this.baixaSemanalAllUser.map((el) => {
          if (this.baixaSemanalAllUser[0].quantidade === el.quantidade) {
            this.maiorBaixa.push(el);
          }
        });

       

        this.baixaSemanalAllUser.sort(function (a, b) {
          if (a.quantidade > b.quantidade) return -1;
          if (a.quantidade < b.quantidade) return 1;
          return 0;
        });

        
      } catch (error) {
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Ainda não existe baixa na semana",
            type: "info",
          });
        }
        this.$toast.open({
          message: "Erro ao listar baixas da semana",
          type: "error",
        });
      }
    },

    // async emAatendimento() {
    //   const url = `/schedules/report/count`;
    //   const { data } = await http.get(url);

    // },

    async listClientes() {
      try {
        const url = `/customers/list/combobox`;
        const { data } = await http.get(url);
        this.$store.commit("clientes", data);
      } catch (error) {
        this.$toast.open({
          message: "Erro ao listar clientes",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async listSoftware() {
      try {
        const url = `/softwares/list/combobox`;
        const { data } = await http.get(url);
        this.$store.commit("softwares", data);
      } catch (error) {
        this.$toast.open({
          message: "Erro ao listar software",
          type: "error",
          // all of other options may go here
        });
      }
    },
  },
};
</script>

<style scoped>
.list-group-item {
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .cardBaixa card {
    margin: 0 !important;
    width: 100%;
    border: 33px solid black !important;
  }

  .rowBaixa {
    width: 100% !important;
  }
}

.rowBaixa {
  margin: 0 auto;
  border-radius: 10px;
  width: 50%;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.cardBaixa {
  margin-top: 20px !important;
  background-color: white;
  border-radius: 5px;
}
.card-deck .card {
  transition: 0.5s ease-out;
}
/* .card-deck .card:hover {
  -webkit-box-shadow: 0px 10px 13px -7px #666,
    6px 2px 18px 5px 666(0, 0, 0, 0.58);
  box-shadow: 0px 10px 13px -7px #666, 6px 2px 18px 5px rgba(0, 0, 0, 0.58) !important;
} */

.atendimento {
  display: felx;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
}

</style>
