<template>
  <div>
    <div v-for="(atendimento, index) in relatorioAtendimento" :key="index">
      <table :id="`cliente${index}`">
        <thead>
          <tr>
            <th scope="col">Nome Cliente</th>
            <th scope="col">Status</th>
            <th scope="col">Tempo de Atendimento</th>
            <th scope="col">Tipo de Suporte</th>
            <th scope="col">Tempo Maximo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(atendi, index) in atendimento" :key="index">
            <td>{{ atendi.nomeCliente.toLowerCase() }}</td>
            <td>{{ atendi.status }}</td>
            <td>{{ atendi.tempo.toLowerCase() }}</td>
            <td>{{ atendi.tipoSuporte.toLowerCase() }}</td>
            <td>{{ atendi.tempoMaximo }}</td>
          </tr>
        </tbody>
      </table>

      <table id="baixa">
        <thead>
          <tr>
            <th scope="col">Nome Atendente</th>
            <th scope="col">Baixas</th>
          
          </tr>
        </thead>
        <tbody>
          <tr v-for="(atendi, index) in relatorioBaixas" :key="index">
            <td>{{ atendi.nome.toLowerCase() }}</td>
            <td>{{ atendi.baixas }}</td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  props: {
    relatorioAtendimento: {
      type: Object,
    },

     relatorioBaixas: {
      type: Array,
    },
  },
  methods: {
    gerarPDF() {
      const doc = new jsPDF("l", "mm", [257, 250]);
      let linha = 10;

       doc
          .setFontSize(14)
          .setTextColor(155)
          .text("Baixas Suporte", 105, linha - 3);
      this.relatorioBaixas.forEach(() => {
        
        doc.autoTable({
          html: `#baixa`,
          margin: { horizontal: 5 },
          styles: { fontSize: 12 },
          startY: linha,
          tableWidth: 250,
          
        });
      });
      doc.addPage()

      for (const key in this.relatorioAtendimento) {
        //  const y = this.relatorioAtendimento[key].length * linha;
        doc
          .setFontSize(10)
          .setTextColor(155)
          .text(key.toUpperCase(), 97, linha - 3);
        doc
          .setFontSize(7)
          .setTextColor(155)
          .text(new Date().toLocaleDateString(), 125, linha - 3);
       
        doc.autoTable({
          html: `#cliente${key}`,
          margin: { horizontal: 5 },
          styles: { fontSize: 7 },
          startY: linha,
          tableWidth: 250,
          
        });
        doc.addPage()
        
      }
     
      
      doc.deletePage(doc.getNumberOfPages())
      doc.save("RelatorioAtendimentos.pdf");
      this.$emit("limpaDados");
    },
  },

  watch: {
    relatorioAtendimento() {
      console.log(this.relatorioAtendimento);
      setTimeout(() => {
        this.gerarPDF();
      }, 1000);
    },
  },
};
</script>

<style>
</style>