<template>
  <div>
    <MenuBar />
    <b-card no-body class="m-2 p-4">
      <header>
        <p class="title">Dados do responsável técnico</p>

        <div class="close" @click="close">
          <p class="textClose">x</p>
        </div>
      </header>
      <FormResponsavel></FormResponsavel>
    </b-card>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import { http } from "../../services/config";
import FormResponsavel from "../../components/forms/FormResponsavel.vue";

export default {
  components: {
    MenuBar,
    FormResponsavel,
  },
  data() {
    return {
      tabIndex: 0,
      users: [],
      userSelect: {},
      keys: [{ key: "username" }],
      fields: [{ titulo: "Nome do Usuário" }, { titulo: "Editar" }],
    };
  },

  methods: {
    async editar(nome) {
      try {
        const url = `/users/${nome}`;
        const { data } = await http.get(url);
        this.userSelect = data;
        this.tabIndex = 0;
      } catch (error) {
        this.$swal("Usuário", "erro ao editar Usuário", "error");
      }
    },
    async listUser() {
      try {
        const url = `/users/list/combobox`;
        const { data } = await http.get(url);
        this.users = data;
      } catch (error) {
        this.$swal("Usuário", "erro ao listar Usuário", "error");
      }
    },
    close() {
      this.$router.push(`/responsavel`);
    },
  },
};
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.textClose {
  height: 100%;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
}

.close {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: solid 1px #dc3545;
  border-radius: 25%;
  cursor: pointer;
  background-color: red;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.57);
}
</style>