import { http } from "./config";

export const save = async (post) => {
  const data = await http.post("/blog", post);
  return data;
};

export const deletePost = async (idPost) => {
  const data = await http.delete(`/blog/${idPost}`);
  return data;
};

export const update = async (post, idPost) => {
  const data = await http.put(`/blog/${idPost}`, post);
  return data;
};

export const findById = async (idPost) => {
  const data = await http.get(`/blog/${idPost}`);
  return data;
};

export const showAll = async () => {
  const data = await http.get("/blog/post/all");
  return data;
};
