<template>
  <div>
    <div class="row" style="margin-bottom: 10px">
      <b-form class="col-sm-8 row">
        <b-form-group label="Nome" class="col-sm-4">
          <b-form-input
            type="text"
            placeholder="Nome cliente"
            size="sm"
            required
            v-model="form.nome"
          ></b-form-input>
          <b-form-text>
            <span style="color: red">{{ formMessage.nome }}</span>
          </b-form-text>
        </b-form-group>

        <b-form-group label="Tipo de Pessoa" class="col-sm-2">
          <b-form-select
            v-model="selected"
            :options="tipoPessoa"
            size="sm"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="selected === 'cpf'" label="CPF" class="col-sm-2">
          <b-form-input
            type="text"
            placeholder="CPF"
            size="sm"
            required
            v-mask="'###.###.###-##'"
            v-model="form.cpfcnpj"
          ></b-form-input>
          <b-form-text style="color: red">
            <span style="color: red">{{ formMessage.cpfcnpj }}</span>
          </b-form-text>
        </b-form-group>

        <b-form-group v-if="selected === 'cnpj'" label="CNPJ" class="col-sm-3">
          <b-form-input
            type="text"
            placeholder="CNPJ"
            size="sm"
            v-mask="'##.###.###/####-##'"
            required
            v-model="form.cpfcnpj"
          ></b-form-input>
          <b-form-text style="color: red">
            <span style="color: red">{{ formMessage.cpfcnpj }}</span>
          </b-form-text>
        </b-form-group>

        <b-form-group label="Inscrição Estadual" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="form.inscricaoEstadual"
            placeholder="Inscrição Estadual"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Inscrição municipal" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="form.im"
            placeholder="Inscrição municipal"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Endereço" class="col-sm-4">
          <b-form-input
            type="text"
            v-model="form.endereco"
            placeholder="Endereço"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Bairro" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="form.bairro"
            placeholder="Bairro"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Número" class="col-sm-2">
          <b-form-input
            type="text"
            v-model="form.numero"
            placeholder="Número"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Complemento" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="form.complemento"
            placeholder="Complemento"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Cidade" class="col-sm-3">
          <b-form-input
            type="text"
            v-model="form.cidade"
            placeholder="Cidade"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="UF" class="col-sm-1">
          <b-form-input
            type="text"
            v-model="form.uf"
            placeholder="UF"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="CEP" class="col-sm-2">
          <b-form-input
            v-b-tooltip.focus.top
            title="Digite o Cep e presione ENTER para buscar"
            type="text"
            placeholder="CEP"
            size="sm"
            v-mask="'#####-###'"
            v-model="form.cep"
            @keyup.enter="findCityByCep"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Telefone" class="col-sm-3">
          <b-form-input
            type="text"
            placeholder="Telefone"
            size="sm"
            required
            v-model="form.telefone"
            v-mask="'(##) ####-####'"
          ></b-form-input>
          <b-form-text style="color: red">
            <span style="color: red">{{ formMessage.telefone }}</span>
          </b-form-text>
        </b-form-group>

        <b-form-group label="Celular" class="col-sm-3">
          <b-form-input
            type="text"
            placeholder="Celular"
            size="sm"
            required
            v-mask="'(##) # ####-####'"
            v-model="form.celular"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email" class="col-sm-4">
          <b-form-input
            type="email"
            placeholder="Email"
            size="sm"
            required
            v-model="form.email"
          ></b-form-input>
          <b-form-text style="color: red">
            <span style="color: red">{{ formMessage.email }}</span>
          </b-form-text>
        </b-form-group>
        <b-form-group label="Vendedora" class="col-sm-5">
          <b-form-select
            v-model="form.userId"
            :options="saleUsers"
            size="sm"
            value-field="uuid"
            text-field="username"
          />
        </b-form-group>
      </b-form>
      <div class="col-sm-4">
        <h5>Informações</h5>
        <b-button variant="success" size="sm" class="mr-2" @click="desbloqueio"
          >Desbloquear</b-button
        >
        <b-button variant="danger" size="sm" @click="bloqueio"
          >Bloquear</b-button
        >
        <!-- <b-form-checkbox size="lg" v-if="!status"  v-model="status"> Bloquear Cliente </b-form-checkbox>
        <b-form-checkbox size="lg" v-if="status" v-model="status"> Cliente Bloqueado</b-form-checkbox> -->
        <br />
      </div>
    </div>
    <b-button type="submit" size="sm" @click="save" variant="info">
      Salvar
    </b-button>
    <b-button
      type="reset"
      size="sm"
      variant="info"
      style="margin-left: 5px"
      @click="limpar"
    >
      Novo
    </b-button>
  </div>
</template>

<script>
import { http } from "../../services/config";
import { findCep } from "../../services/ServiceCep";

export default {
  name: "frmCliente",
  props: {
    clienteSelecionado: {
      type: Object,
    },
  },
  data() {
    return {
      selected: "cnpj",
      tipoPessoa: [
        { text: "CNPJ", value: "cnpj" },
        { text: "CPF", value: "cpf" },
      ],
      saleUsers: [
        {
          uuid: null,
          username: "Selecione uma vendedora",
        },
      ],
      status: false,
      uuidForm: "",
      form: {
        userId: null,
        nome: "",
        cpfcnpj: "",
        inscricaoEstadual: "",
        endereco: "",
        bairro: "",
        numero: "",
        complemento: "",
        cidade: "",
        uf: "",
        cep: "",
        email: "",
        telefone: "",
        celular: "",
        im: "",
      },
      formMessage: {
        nome: "",
        cpfcnpj: "",
        email: "",
        telefone: "",
        celular: "",
      },
    };
  },

  methods: {
    limpar() {
      (this.uuidForm = ""),
        (this.form = {
          nome: "",
          cpfcnpj: "",
          email: "",
          telefone: "",
          celular: "",
        });
    },
    async bloqueio() {
      try {
        const url = `/activations/customer/block/${this.uuidForm}`;
        await http.put(url, { bloqueado: true });
        this.$swal("Cliente", "Cliente Bloqueado", "success");
      } catch (error) {
        if (error.response.status === 422) {
          this.$toast.open({
            message: "Link do Sistema : " + error.response.data.erros.bloqueado,
            type: "error",
            // all of other options may go here
          });
          return;
        }
        this.$swal("Cliente", "Ocorreu um erro ao Bloquear Cliente", "error");
      }
    },

    async desbloqueio() {
      try {
        const url = `/activations/customer/block/${this.uuidForm}`;
        await http.put(url, { bloqueado: false });
        this.$swal("Cliente", "Cliente Desbloqueado", "success");
      } catch (error) {
        if (error.response.status === 422) {
          this.$toast.open({
            message: "Link do Sistema : " + error.response.data.erros.bloqueado,
            type: "error",
            // all of other options may go here
          });
          return;
        }

        this.$swal(
          "Cliente",
          "Ocorreu um erro ao Desbloquear Cliente",
          "error"
        );
      }
    },
    async bloquearCliente() {},

    async reloadClientesStore() {
      try {
        const url = `/customers/list/combobox`;
        const { data } = await http.get(url);
        this.$store.commit("clientes", data);
      } catch (error) {
        this.$toast.open({
          message: "erro ao preencher combobox",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async save() {
      try {
        if (!this.checkForm()) {
          return;
        }
        if (this.uuidForm != "") {
          await http.put(`/customers/${this.uuidForm}`, this.form);
          this.$swal("Cliente", "Cliente Atualizado com Sucesso", "success");
          this.reloadClientesStore();
          return;
        }
        this.formMessage = {
          nome: "",
          cpfcnpj: "",
          email: "",
          telefone: "",
          celular: "",
        };
        this.form.cpfcnpj = this.form.cpfcnpj
          .replace("-", "")
          .replace(".", "")
          .replace(".", "")
          .replace("/", "");
        const { data } = await http.post("/customers", this.form);
        this.uuidForm = data.uuid;
        this.$swal("Cliente", "Cliente cadastro com sucesso.", "success");
        this.reloadClientesStore();
      } catch (error) {
        if (error.response.status === 422) {
          const teste = error.response.data.erros[0];
          const key = Object.keys(teste)[0];
          const value = Object.values(teste)[0];
          this.formMessage[key] = value;
          return;
        }

        if (error.response.status === 409) {
          this.$swal("Cliente", "Cliente já existe", "info");
          return;
        }

        this.$swal("Cliente", "Ocorreu um erro no cadastro.", "error");
      }
    },
    checkForm() {
      if (!!this.form.nome && !!this.form.cpfcnpj) {
        return true;
      }

      for (const key in this.form) {
        if (this.form[key] == "") {
          this.formMessage[key] = "Este campo é obrigatório.";
        } else {
          this.formMessage[key] = "";
        }
      }
      return false;
    },

    async findCityByCep() {
      try {
        const result = await findCep(this.form.cep);

        this.form = {
          ...this.form,
          bairro: result.bairro,
          endereco: result.logradouro,
          uf: result.uf,
          complemento: result.complemento,
          cidade: result.localidade,
          cep: result.cep,
        };
      } catch (error) {
        return this.$toast.open({
          message: error.message,
          type: "error",
        });
      }
    },

    async findSaleUsers() {
      const { data } = await http.get("/users/find/sale-users/combobox");
      if (data && data.length) this.saleUsers = [...this.saleUsers, ...data];
    },
  },
  computed: {},
  watch: {
    async clienteSelecionado() {
      Object.assign(this.form, this.clienteSelecionado);
      this.uuidForm = this.clienteSelecionado.uuid;
      this.status = this.clienteSelecionado.bloqueado;
    },
  },
  mounted() {
    this.findSaleUsers();
  },
};
</script>

<style scoped>
</style>
