<template>
  <div>
    <div class="flexDiv">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <b-row class="col-sm-12">
          <b-form-group
            label="Filtro"
            class="col-sm-6 col-md-6 col-lg-4 col-xl-4"
          >
            <b-form-select
              size="sm"
              @change="showNumberFiscalDocuments"
              :options="filters"
              v-model="payload.filter"
            />
          </b-form-group>

          <b-form-group
            label="Qtd"
            class="col-sm-6 col-md-6 col-lg-2 col-xl-2"
            v-if="fiscalDocumentsIsEnable"
          >
            <b-input
              size="sm"
              type="number"
              v-model="payload.amountDocuments"
            />
          </b-form-group>

          <b-form-group
            label="Data inicio"
            class="col-sm-6 col-md-6 col-lg-3 col-xl-3"
            v-if="dateEnable"
          >
            <b-input type="date" size="sm" v-model="payload.startDate" />
          </b-form-group>

          <b-form-group
            label="Data fim"
            class="col-sm-6 col-md-6 col-lg-3 col-xl-3"
            v-if="dateEnable"
          >
            <b-input type="date" size="sm" v-model="payload.endDate" />
          </b-form-group>
        </b-row>

        <b-row class="col-sm-12 justify-content-end">
          <b-form-group>
            <b-button size="sm" variant="primary" @click="search"
              >Pesquisar</b-button
            >
          </b-form-group>
        </b-row>

        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Tipo plano</th>
              <th scope="col">Periodo</th>
              <th scope="col">Docs disponíveis</th>
              <th scope="col">Docs totais</th>
              <th scope="col">Ativo</th>
              <th scope="col">Data vencimento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="plan in plans" :key="plan.uuid">
              <td>{{ plan.cliente.nome }}</td>
              <td>{{ plan.tipoPlano.replace("_", " ") }}</td>
              <td>{{ plan.periodoAtuacao }}</td>
              <td>{{ plan.documentosRestantes }}</td>
              <td>{{ plan.limiteDocumentos }}</td>
              <td>{{ plan.ativo ? "SIM" : "NÃO" }}</td>
              <td>
                {{
                  plan.dataFim
                    ? plan.dataFim.split("T")[0].split("-").reverse().join("/")
                    : "PACOTE EXTRA"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <nav aria-label="Page navigation example" class="mt-4">
      <ul class="pagination" id="paginacao">
        <li class="page-item1 mr-3">
          <b-button
            class="button-previous"
            type="button"
            @click="previousPage"
            :disabled="payload.page === 1 ? true : false"
          >
            <b-icon-arrow-left-square-fill
              class="ml-2"
              scale="1.5"
              style="cursor: pointer"
              variant="primary"
            ></b-icon-arrow-left-square-fill>
          </b-button>
        </li>
        <li class="page-item">
          <b-button
            class="button-next"
            type="button"
            id="ProximoPagina"
            @click="nextPage"
            :disabled="!plans.length ? true : false"
          >
            <b-icon-arrow-right-square-fill
              scale="1.5"
              style="cursor: pointer"
              variant="primary"
            ></b-icon-arrow-right-square-fill>
          </b-button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { http } from "../../services/config";
export default {
  data() {
    return {
      payload: {
        filter: null,
        startDate: null,
        endDate: null,
        amountDocuments: null,
        page: 1,
      },
      filters: [
        { text: "EM ABERTO", value: "em_aberto" },
        { text: "VENCIDOS", value: "vencidos" },
        { text: "DOCUMENTOS FISCAIS", value: "fiscal" },
      ],
      fiscalDocumentsIsEnable: false,
      dateEnable: false,
      plans: [],
    };
  },

  components: {},
  created() {},
  methods: {
    showNumberFiscalDocuments() {
      if (this.payload.filter === "fiscal") {
        this.fiscalDocumentsIsEnable = true;
        this.dateEnable = true;
      } else {
        this.fiscalDocumentsIsEnable = false;
        this.dateEnable = false;
        this.payload.startDate = "";
        this.payload.endDate = "";
      }
    },
    async search() {
      try {
        const { filter, amountDocuments, startDate, endDate, page } =
          this.payload;

        const { data } = await http.get(
          `/plans/search/per/page?filter=${filter}&amountDocuments=${amountDocuments}&startDate=${startDate}&endDate=${endDate}&page=${page}`
        );

        this.plans = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.payload.page -= 1;
          return this.$toast.open({
            message: "Nenhum Registro Encontrado",
            type: "info",
            // all of other options may go here
          });
        }

        this.$toast.open({
          message: "erro ao pesquisar planos",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async nextPage() {
      this.payload.page += 1;
      this.search();
    },

    async previousPage() {
      this.payload.page -= 1;
      this.search();
    },
  },
};
</script>

<style scoped>
.button-previous {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.button-next {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

@media (max-width: 700px) {
  .flexDiv {
    width: 100%;
  }
}
</style>