<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark" class="p-3 mb-4">
      <b-navbar-brand v-b-toggle.sidebar-variant class="menuBtn">
        <b-icon-menu-down></b-icon-menu-down> Menu</b-navbar-brand
      >
      <b-navbar-brand to="/home" class="menuBtn">
        <b-icon-house-door-fill></b-icon-house-door-fill> Home</b-navbar-brand
      >

      <!-- <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Cadastros" left>
            <b-dropdown-item to="/cliente">Clientes</b-dropdown-item>
            <b-dropdown-item to="/software">Softwares</b-dropdown-item>
            <b-dropdown-item href="/usuario">Usuário</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item href="/agenda">Agenda</b-nav-item>
          <b-nav-item to="/liberar">Liberações</b-nav-item>
          <b-nav-item href="/ponto">Ponto</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              <em>{{ user }}</em>
            </template>
            <b-dropdown-item href="/logout">Sair</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse> -->
    </b-navbar>
    <div>
      <b-sidebar
        id="sidebar-variant"
        bg-variant="dark"
        text-variant="light"
        shadow
      >
        <template #footer="{ hide }">
          <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
            <strong class="mr-auto">BMS LTDA</strong>
            <b-button size="sm" to="/" @click="sair"
              ><b-icon-x-circle></b-icon-x-circle> Sair</b-button
            >
            <b-button size="sm" class="ml-2" to="/log" @click="hide"
              ><b-icon-card-list></b-icon-card-list> Logs</b-button
            >
          </div>
        </template>
        <div class="mb-2" style="display: flex">
          <b-avatar
            style="margin: 0 auto"
            :src="logoBms"
            size="6rem"
          ></b-avatar>
        </div>
        <h3 class="text-center">{{ user }}</h3>
        <div class="px-3 py-2">
          <b-button
            variant="primary"
            :hidden="user === 'claudia'"
            block
            size="sm"
            to="/agenda"
            ><b-icon-calendar2-day-fill
              class="mr-2"
            ></b-icon-calendar2-day-fill>
            Agenda</b-button
          >

          <b-button
            variant="primary"
            :hidden="user === 'claudia'"
            block
            size="sm"
            to="/liberar"
            ><b-icon-unlock-fill class="mr-2"></b-icon-unlock-fill>
            Liberação</b-button
          >

          <b-button
            v-b-toggle.collapse-a
            size="sm"
            variant="primary"
            block
            class="mt-2"
          >
            <b-icon-journal-plus class="mr-2"></b-icon-journal-plus>
            Cadastros</b-button
          >
          <b-collapse id="collapse-a" class="mt-2 text-center">
            <b-button
              size="sm"
              variant="primary"
              to="/cliente"
              :hidden="user === 'claudia'"
              class="mt-2"
            >
              <b-icon-person-circle></b-icon-person-circle> Cliente</b-button
            >
            <b-button
              size="sm"
              variant="primary"
              to="/software"
              class="mt-2 ml-2"
            >
              <b-icon-laptop></b-icon-laptop> Software</b-button
            >
            <b-button
              size="sm"
              variant="primary"
              to="/user"
              :hidden="user === 'claudia'"
              class="mt-2 ml-2"
            >
              <b-icon-file-person-fill></b-icon-file-person-fill>
              Usuário</b-button
            >
          </b-collapse>


          <b-button
            v-b-toggle.collapse-winfit
            size="sm"
            variant="primary"
            block
            class="mt-2"
          >
            <b-icon-tree class="mr-1"></b-icon-tree>
            Admin Winfit</b-button
          >
          <b-collapse id="collapse-winfit" class="mt-2 text-center">
            <b-button
              size="sm"
              variant="primary"
              to="/responsavel"
              :hidden="user === 'claudia'"
              class="mt-2"
            >
              <b-icon-person-circle></b-icon-person-circle> Responsável</b-button
            >
            <b-button
              size="sm"
              variant="primary"
              to="/blog"
              class="mt-2 ml-2"
            >
              <b-icon-hand-index-thumb></b-icon-hand-index-thumb> Blog</b-button
            >
          </b-collapse>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import logoBms from "../assets/LogoBms2.jpg";
// import { http } from '../services/config';
export default {
  name: "MenuBar",
  data() {
    return {
      logoBms: logoBms,
      user: sessionStorage.getItem("username"),
      setor: "",
    };
  },

  methods: {
    sair() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("setor");
    },
  },
};
</script>

<style scoped>
.menuBtn {
  transition: 0.9s;
}
.menuBtn:hover {
  color: rgb(83, 125, 241);
}
</style>
