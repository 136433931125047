<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th v-for="field in fields" :key="field.titulo">{{ field.titulo }}</th>
      </tr>
    </thead>
    <tbody v-if="dataProps">
      <tr v-for="data in dataProps" :key="data.uuid">
        <td v-for="key in keys" :key="key.uuid">{{ data[key.key] }}</td>
        <td>
          <slot name="buttonAcoes" v-bind:uuid="data.uuid"></slot>
        </td>
        <td>
          <slot name="buttonAcoes6" v-bind:linkamazon="data.linkamazon"></slot>
        </td>
        <td>
          <slot name="buttonAcoes5" v-bind:cpfcnpj="data.cpfcnpj"></slot>
        </td>
        <td>
          <slot name="buttonAcoes4" v-bind:uuid="data.uuid"></slot>
        </td>
        <td>
          <slot name="buttonAcoes2" v-bind:uuid="data.uuid"></slot>
        </td>
        <td>
          <slot name="buttonAcoes3" v-bind:chave="data.chave"></slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
    },
    quantSlot: {
      type: String,
    },
    dataProps: {
      type: Array,
    },
    keys: {
      type: Array,
    },
    slots: {
      type: Array,
    },
  },
  data() {
    return {
      // fields: [{titulo: "Teste"}, {titulo: "Brincadeira"}]
    };
  },

  methods: {
    setUuidCliente(uuid) {
      this.$store.commit("uuidCliente", uuid);
      this.$emit("editarCliente");
    },
  },
};
</script>

<style>
</style>