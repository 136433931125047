<template>
  <div>
    <MenuBar />
    <div class="contentCard">
      <b-card no-body>
        <b-tabs card v-model="tabIndex">
          <b-tab title="Cadastro Software">
            <b-card>
              <FormSoftware :softwareProp="software" />
            </b-card>
          </b-tab>
          <b-tab title="Pesquisa">
            <b-card>
              <b-form v-on:submit.prevent> 
                <b-form-group label="Nome do Software">
                  <b-form-input
                  @keyup.enter="listaSoftware"
                    placeholder="Software"
                    v-model="valuePesquisa"
                    size="sm"
                    class="col-sm-5"
                    @dblclick="listaSoftware"
                  >
                  </b-form-input>
                </b-form-group>
                <b-button
                  variant="primary"
                  size="sm"
                  class="mb-4"
                  @click="listaSoftware"
                  >Pesquisar</b-button
                >
              </b-form>

              <Table
                :fields="fields"
                :dataProps="softwareProps"
                :keys="keys"
                :slots="slots"
              >
                <template #buttonAcoes="{ uuid }">
                  <b-button
                    size="sm"
                    pill
                    :variant="colorBtn"
                    @click="editarSoftware(uuid)"
                    >Editar</b-button
                  >
                </template>
                <!-- <template #buttonAcoes6="{ linkamazon }">
                  <b-button
                    size="sm"
                    pill
                    :variant="colorBtn"
                    v-b-tooltip.hover
                    :title="copy"
                    @click="copiarLink(linkamazon)"
                    ><b-icon-cloud-arrow-down></b-icon-cloud-arrow-down> Copiar
                    Link</b-button
                  >
                </template> -->
              </Table>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import Table from "../../components/Table/Table.vue";
import FormSoftware from "../../components/forms/FormSoftware.vue";
import { http } from "../../services/config";
export default {
  components: {
    MenuBar,
    FormSoftware,
    Table,
  },
  data() {
    return {
      tabIndex: 0,
      valuePesquisa: "",
      colorBtn: "primary",
      fields: [
        { titulo: "Software" },
        { titulo: "Versão" },
        { titulo: "Editar" },
        // { titulo: "Copiar Link do Sistema" },
      ],
      copy: "Copiar",
      slots: [{ slot: "1" }, { slot: "6" }],
      software: {},
      keys: [{ key: "nomesoftware" }, { key: "versao" }],
      softwareProps: [],
    };
  },
  methods: {
    async copiarLink(link) {
      console.log(link)
      await navigator.clipboard.writeText(link);
      this.copy = "Link Copiado";
      setTimeout(() => {
        this.copy = "Copiar";
      }, 1000);
    },
    async editarSoftware(uuid) {
      try {
        const url = `/softwares/${uuid}`;
        const { data } = await http.get(url);
        this.software = data;
        this.tabIndex = 0;
      } catch (error) {
        this.$swal("Software", "Ocorreu um erro ao editar Software");
      }
    },

    async listaSoftware() {
      try {
       
        const url = `/softwares/search/pagination?nomesoftware=${encodeURI(
          this.valuePesquisa
        )}`;
        const { data } = await http.get(url);
        this.softwareProps = data;
      } catch (error) {
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Registro Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Software", "Ocorreu um erro ao listar Softwares", "error");
      }
    },
  },
};
</script>

<style>
.contentCard {
  margin: 10px;
}
</style>